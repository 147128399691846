<template>
  <v-card class="ml-13" flat height="100vh">
    <v-container fluid grid-list class="ma-0 pa-0">
      <v-layout wrap d-flex justify-start class="ma-0 pa-0">
        <v-flex xs12 sm12 md2>
          <v-card flat tile height="100vh">
            <ZoneSelector />
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md10>
          <v-card
            tile
            height="100vh"
            color="#F5F5F5"
            class="pa-3"
            elevation="0"
          >
            <v-card elevation="0">
              <v-card-title class="d-flex align-center pt-8 pb-6">
                <div class="d-flex align-center">
                  <v-icon color="#A3AEFF" class="mr-3"
                    >mdi-account-circle</v-icon
                  >
                  <p class="text-body-1 ma-0 font-weight-bold">Usuarios</p>
                  <v-icon class="mr-2 ml-4" small color="#6F50FF"
                    >mdi-help-circle-outline</v-icon
                  >
                  <p class="text-caption ma-0 mr-10">
                    Crea y administra los perfiles de los colaboradores de tu
                    empresa.
                  </p>
                </div>
                <div class="d-flex align-center">
                  <p
                    class="text-caption ma-0 font-weight-bold pt-1 pb-1 pl-2 pr-2 rounded"
                    style="background: #f2f4f7; color: #a4afc0"
                  >
                    {{ this.remainingUsers }}
                  </p>
                  <v-divider vertical class="mx-3"></v-divider>
                  <p
                    v-if="remainingUsers !== 0"
                    class="text-caption ma-0 font-weight-light"
                  >
                    Usuarios Disponibles
                  </p>
                  <p
                    v-else
                    class="text-caption ma-0 font-weight-light red--text font-weight-medium"
                  >
                    Sin Usuarios Disponibles
                  </p>
                  <v-btn
                    small
                    color="#8739EB"
                    rounded
                    outlined
                    :disabled="remainingUsers === 0"
                    class="ml-10 text-none"
                    to="/new-user"
                  >
                    <v-icon small class="mr-1">mdi-plus-circle-outline</v-icon>
                    Nuevo Usuario
                  </v-btn>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card outlined tile height="80vh" elevation="0">
                <TableUsers />
              </v-card>
            </v-card>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import TableUsers from "../../components/Users/TableUsers.vue";
import ZoneSelector from "../../components/App/ZoneSelector.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TableUsers,
    ZoneSelector,
  },
  data() {
    return {
      maximumUsers: 0,
      usersCreated: 0,
      remainingUsers: 0,
    };
  },

  watch: {
    getCompany() {
      this.maximumUsers = this.getCompany.company_maximum_users;
      this.usersCreated = this.getCompany.company_users;
      this.remainingUsers =
        this.getCompany.company_maximum_users - this.getCompany.company_users;
    },
  },

  computed: {
    ...mapGetters(["getCompany"]),
  },

  beforeCreate() {
    this.$store.dispatch("requestsAllCompanies");
  },
};
</script>

<style></style>
