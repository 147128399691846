<template>
  <div class="pb-5">
    <v-alert
      :value="alert"
      dense
      text
      type="success"
      transition="scale-transition"
      v-if="userCreated"
    >
    <p class="caption pa-0 ma-0">¡El usuario se creó con éxito!</p>
    </v-alert>
    <v-alert
      :value="alert"
      dense
      text
      type="success"
      transition="scale-transition"
      v-if="userDeleted"
    >
    <p class="caption pa-0 ma-0">¡El usuario se eliminó con éxito!</p>
    </v-alert>
    <v-card
      v-if="vacanciesSelected === true"
      class="d-flex flex-row pa-3"
      flat
      tile
    >
      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            ¿Estás seguro realizar esta acción?
          </v-card-title>

          <v-card-text>
            Eliminarás de forma permanente a el/los usuario(s) seleccionados.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="deep-purple accent-4"
              text
              @click="(dialog = false), deleteCandidates()"
            >
              Acepto
            </v-btn>

            <v-btn color="deep-purple accent-4" text @click="dialog = false">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn color="#F86E80" fab depressed x-small dark @click="dialog = true">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </v-card>
    <template>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="getAllUsers"
        hide-default-footer
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        item-key="uid"
        show-select
        class="elevation-0"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-hover v-slot="{ hover }">
            <v-skeleton-loader
              type="list-item"
              class="ma-0 pa-0"
              :loading="loading"
              style="width: 10rem"
            >
              <router-link
                :to="{ path: 'users/detail-user/' + `${item.uid}` }"
                style="text-decoration: none"
                @click="SELECT_VACANCY(this.$route.params.uid)"
              >
                <v-layout
                  justify-start
                  class="font-weight-bold caption"
                  :style="{ color: hover ? '#8739EB' : '#000' }"
                >
                  {{ item.name }}
                </v-layout>
              </router-link>
            </v-skeleton-loader>
          </v-hover>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <v-skeleton-loader
            type="list-item"
            class="ma-0 pa-0"
            :loading="loading"
            style="width: 4rem"
          >
            <p class="ma-0 pa-0 caption">{{ getTypeUser(item.type) }}</p>
          </v-skeleton-loader>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <v-skeleton-loader
            type="list-item"
            class="ma-0 pa-0"
            :loading="loading"
            style="width: 12rem"
          >
            <p class="ma-0 pa-0 caption">{{ item.email }}</p>
          </v-skeleton-loader>
        </template>
        <template v-slot:[`item.phoneNumber`]="{ item }">
          <v-skeleton-loader
            type="list-item"
            class="ma-0 pa-0"
            :loading="loading"
            style="width: 5rem"
          >
            <p class="ma-0 pa-0 caption">{{ item.phoneNumber }}</p>
          </v-skeleton-loader>
        </template>
      </v-data-table>
      <v-pagination
        elevation="0"
        v-model="page"
        :length="pageCount"
        class="custom caption mt-4"
        small
        color="#A3A1FB"
        circle
      ></v-pagination>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  data: () => ({
    dialog: false,

    search: "",
    // singleSelect: false,
    selected: [],
    //idLink: '',
    headers: [
      { text: "Nombre", align: "start", value: "name", sortable: false },
      { text: "Nivel de Usuario", value: "type" },
      //{ text: "# Candidatos", value: "candidatesNumber" },
      { text: "Correo", value: "email", align: "center" },
      { text: "Teléfono", value: "phoneNumber", align: "center" },
      //{ text: "Estado", value: "isActive", align: "center" },
      //{ text: "Interes", value: "status", align: "center" },
      //{ text: "Reclutadores", value: "recruiters" },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    loading: false,
  }),
  computed: {
    ...mapGetters(["getAllUsers"]),
    vacanciesSelected() {
      return this.selected.length > 0 ? true : false;
    },
    ...mapState({
      selectedZone: (state) => state.vacancies.selectedZone,
    }),
    userCreated(){
      return this.$store.state.auth.userCreated
    },
    userDeleted(){
      return this.$store.state.users.deletedUser
    }
  },
  methods: {
    ...mapActions([
      "requestsAllUsers",
      "deleteUsersById",
      "requestsAllCompanies",
    ]),
    getText(status) {
      switch (status) {
        case true:
          return "Activo";
        case false:
          return "Inactivo";
      }
    },
    getColor(status) {
      switch (status) {
        case true:
          return "#10DC10";
        case false:
          return "#000000";
      }
    },
    deleteCandidates() {
      const usersIds = [];
      this.selected.forEach((candidate) => {
        usersIds.push(candidate.uid);
      });
      this.deleteUsersById(usersIds).then(() => {
        this.selected = [];
        setTimeout(() => {
          this.requestsAllUsers(this.selectedZone);
          this.requestsAllCompanies();
        }, 2000);
      });
    },
    getTextColor(status) {
      return "#FFFFFF";
    },
    getTypeUser(user) {
      if (user == "ADMIN") {
        return "Admin";
      } else {
        return "Reclutador";
      }
    },
  },
  watch:{
    userCreated() {
      return setTimeout(
        () => this.$store.commit("CREATED_USER_ALERT", false),
        3000
      );
    },
    userDeleted(){
      return setTimeout(
        () => this.$store.commit("CREATED_USER_ALERT", false),
        3000
      );
    }
  },
  mounted() {},
};
</script>

<style>
.v-pagination__item {
  height: 28px !important;
  min-width: 28px !important;
}
.v-pagination__navigation {
  height: 28px !important;
  width: 28px !important;
}
</style>
