<template>
  <div tile style="background-color: #43455e; height: 100%">
    <v-layout column>
      <v-row class="no-gutters pt-6">
        <p class="white--text text-left ml-10 subtitle-1 font-weight-medium" >
          Zonas
        </p>
        <v-row v-if="zonesStateLoaded === false" class="no-gutters d-flex justify-end pr-6">
          <v-progress-circular color="white" size="20" width="2" indeterminate></v-progress-circular>
        </v-row>
      </v-row>
    </v-layout>

    <v-tabs
      v-if="zonesStateLoaded === true"
      slider-color="fff"
      background-color="#43455E"
      vertical
      flat
    >
      <v-tab
        class="white--text d-flex align-center"
        active-class
        v-for="zone in getAllZones.filter((zone) => zone.zone_is_active && !zone.zone_is_archive)"
        :key="zone.id"
        @change=" zoneSelect(`${zone.zone_id}`)"
      >
        <div class="d-flex pl-4 justify-start" style="width: 100%">
          <v-icon left small> mdi-map-marker-outline </v-icon>
          <p class="pa-0 ma-0 text-capitalize caption text-left">
            {{ zone.company_zone_name }}
          </p>
        </div>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import LoadCard from "../../mixins/LoadCard";
import ENUM from "@/store/enums";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "ZoneSelector",
  mixins: [LoadCard],
  data: () => ({
    tab: null,
    currentTab: null,
  }),
  methods: {
    ...mapActions(["requestsAllZones", "requestAllVacancies", "requestsAllUsers"]),
    zoneSelect( zoneId ){
      if( this.$route.fullPath == '/users' ){
        this.requestsAllUsers(zoneId);
      }else{
        this.requestAllVacancies(zoneId);
      }

    }
  },
  computed: {
    ...mapGetters(["getAllZones"]),
    ...mapState({
      zonesState: (state) => state.zones.status,
      
    }),
    zonesStateLoaded() {
      return this.zonesState === ENUM.LOADED;
    },
    currentView() {
      return this.$route.name;
    }
  },
  mounted() {
    this.requestsAllZones();
  },  
  beforeUpdate() {
    if (this.currentView === "Vacancies" || "Users") {
      this.requestAllVacancies(this.getAllZones[0].zone_id);
      this.requestsAllUsers(this.getAllZones[0].zone_id);
    }
  },
};
</script>
