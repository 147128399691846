var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-5"},[(_vm.userCreated)?_c('v-alert',{attrs:{"value":_vm.alert,"dense":"","text":"","type":"success","transition":"scale-transition"}},[_c('p',{staticClass:"caption pa-0 ma-0"},[_vm._v("¡El usuario se creó con éxito!")])]):_vm._e(),(_vm.userDeleted)?_c('v-alert',{attrs:{"value":_vm.alert,"dense":"","text":"","type":"success","transition":"scale-transition"}},[_c('p',{staticClass:"caption pa-0 ma-0"},[_vm._v("¡El usuario se eliminó con éxito!")])]):_vm._e(),(_vm.vacanciesSelected === true)?_c('v-card',{staticClass:"d-flex flex-row pa-3",attrs:{"flat":"","tile":""}},[_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" ¿Estás seguro realizar esta acción? ")]),_c('v-card-text',[_vm._v(" Eliminarás de forma permanente a el/los usuario(s) seleccionados. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"deep-purple accent-4","text":""},on:{"click":function($event){(_vm.dialog = false), _vm.deleteCandidates()}}},[_vm._v(" Acepto ")]),_c('v-btn',{attrs:{"color":"deep-purple accent-4","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-btn',{attrs:{"color":"#F86E80","fab":"","depressed":"","x-small":"","dark":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)],1):_vm._e(),[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.getAllUsers,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"item-key":"uid","show-select":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-skeleton-loader',{staticClass:"ma-0 pa-0",staticStyle:{"width":"10rem"},attrs:{"type":"list-item","loading":_vm.loading}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ path: 'users/detail-user/' + "" + (item.uid) }},on:{"click":function($event){return _vm.SELECT_VACANCY(this.$route.params.uid)}}},[_c('v-layout',{staticClass:"font-weight-bold caption",style:({ color: hover ? '#8739EB' : '#000' }),attrs:{"justify-start":""}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}],null,true)})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-skeleton-loader',{staticClass:"ma-0 pa-0",staticStyle:{"width":"4rem"},attrs:{"type":"list-item","loading":_vm.loading}},[_c('p',{staticClass:"ma-0 pa-0 caption"},[_vm._v(_vm._s(_vm.getTypeUser(item.type)))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-skeleton-loader',{staticClass:"ma-0 pa-0",staticStyle:{"width":"12rem"},attrs:{"type":"list-item","loading":_vm.loading}},[_c('p',{staticClass:"ma-0 pa-0 caption"},[_vm._v(_vm._s(item.email))])])]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('v-skeleton-loader',{staticClass:"ma-0 pa-0",staticStyle:{"width":"5rem"},attrs:{"type":"list-item","loading":_vm.loading}},[_c('p',{staticClass:"ma-0 pa-0 caption"},[_vm._v(_vm._s(item.phoneNumber))])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-pagination',{staticClass:"custom caption mt-4",attrs:{"elevation":"0","length":_vm.pageCount,"small":"","color":"#A3A1FB","circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }